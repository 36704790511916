<div class="main-container" *ngIf="isAuthenticated$ | async">
  <ng-container *ngIf="{ isSystemAdmin: isSystemAdmin$ | async, user: (userModel$ | async)! } as obs">
    <cui-app-container *ngIf="obs.isSystemAdmin">
      <cui-top-menu [backgroundColor]="topBgColor">
        <cui-top-menu-item class="no-interaction" [iconName]="'person'" [text]="obs.user.userinfo.name"></cui-top-menu-item>
        <cui-top-menu-item [iconName]="'logout'" [tooltipText]="'Log out'" (click)="logout()"></cui-top-menu-item>
      </cui-top-menu>
      <cui-side-nav-container>
        <ng-container class="body">
          <cui-side-nav-item
            icon="dashboard"
            url="/dashboard"
            [name]="'Dashboard'"
            (linkClicked)="onLinkClicked($event)"
          ></cui-side-nav-item>
          <cui-side-nav-item
            *ngIf="hasPartnersMenuAccess$ | async"
            icon="groups"
            url="/partners"
            [name]="'Partners'"
            (linkClicked)="onLinkClicked($event)"
          ></cui-side-nav-item>
          <cui-side-nav-item
            *ngIf="hasUserMenuAccess$ | async"
            icon="people"
            url="/users"
            [name]="'Users'"
            (linkClicked)="onLinkClicked($event)"
          ></cui-side-nav-item>
          <cui-side-nav-expand
            *ngIf="hasProductsAndOrdersMenuAccess$ | async"
            [navExpandNodes]="navExpandProductsAndOrders"
            (linkClicked)="onLinkClicked($event)"
          >
          </cui-side-nav-expand>
          <cui-side-nav-expand *ngIf="hasCspMenuAccess$ | async" [navExpandNodes]="navExpandCsp" (linkClicked)="onLinkClicked($event)">
          </cui-side-nav-expand>
          <cui-side-nav-expand
            *ngIf="hasMarketingMenuAccess$ | async"
            [navExpandNodes]="navExpandMarketing"
            (linkClicked)="onLinkClicked($event)"
          >
          </cui-side-nav-expand>
          <cui-side-nav-expand
            *ngIf="hasNotificationsMenuAccess$ | async"
            [navExpandNodes]="navExpandNotifications"
            (linkClicked)="onLinkClicked($event)"
          >
          </cui-side-nav-expand>
          <cui-side-nav-item
            *ngIf="hasAgreementMenuAccess$ | async"
            icon="assignment"
            url="/agreements"
            [name]="'Agreements'"
            (linkClicked)="onLinkClicked($event)"
          >
          </cui-side-nav-item>
          <cui-side-nav-expand
            *ngIf="hasAuthorizationMenuAccess$ | async"
            [navExpandNodes]="navExpandAuthorization"
            (linkClicked)="onLinkClicked($event)"
          >
          </cui-side-nav-expand>
          <cui-side-nav-item
            *ngIf="hasCommonDataMenuAccess$ | async"
            icon="build_circle"
            url="/common-data"
            [name]="'Common Data'"
            (linkClicked)="onLinkClicked($event)"
          >
          </cui-side-nav-item>
          <cui-side-nav-item
            *ngIf="hasActionLogMenuAccess$ | async"
            icon="troubleshoot"
            url="/action-logs"
            [name]="'Action Logs'"
            (linkClicked)="onLinkClicked($event)"
          ></cui-side-nav-item>
          <cui-side-nav-expand
            *ngIf="hasFileManagementMenuAccess$ | async"
            [navExpandNodes]="navExpandFileManagement"
            (linkClicked)="onLinkClicked($event)"
          >
          </cui-side-nav-expand>
        </ng-container>
      </cui-side-nav-container>
      <div class="body">
        <cui-page-title [title]="pageTitle" [showClose]="false"></cui-page-title>
        <router-outlet></router-outlet>
      </div>
    </cui-app-container>
    <div *ngIf="!obs.isSystemAdmin" class="card">
      <cui-card>
        <cui-card-header>
          <h2>Access Denied</h2>
        </cui-card-header>
        <cui-card-body
          >This user don't have System Administrator access. Click <strong><a class="active-text" (click)="logout()">here</a></strong> to
          logout and try with another user.</cui-card-body
        >
      </cui-card>
    </div>
  </ng-container>
</div>
