import { HttpErrorResponse } from '@angular/common/http';
import { CreateCspTenantRequest, CspTenant, CspTenantListItem, UpdateCspTenantRequest } from '@models/csp/tenants/tenant.model';
import { createAction, props } from '@ngrx/store';
import { State } from './tenants.reducer';

const NAMESPACE = '[FileManagement Tenants]';

export const getAll = createAction(`${NAMESPACE} GetAll`);
export const getAllComplete = createAction(`${NAMESPACE} GetAllComplete`, props<{ tenants: CspTenantListItem[] }>());
export const getAllError = createAction(`${NAMESPACE} GetAllError`, props<{ err: HttpErrorResponse }>());

export const addTenant = createAction(`${NAMESPACE} AddTenant`, props<{ tenant: CreateCspTenantRequest }>());
export const addTenantComplete = createAction(`${NAMESPACE} AddTenantComplete`, props<{ tenant: CspTenant }>());
export const addTenantError = createAction(`${NAMESPACE} AddTenantError`, props<{ err: HttpErrorResponse }>());

export const updateTenant = createAction(`${NAMESPACE} UpdateTenant`, props<{ id: string; tenant: UpdateCspTenantRequest }>());
export const updateTenantComplete = createAction(`${NAMESPACE} UpdateTenantComplete`, props<{ tenant: CspTenant }>());
export const updateTenantError = createAction(`${NAMESPACE} UpdateTenantError`, props<{ err: HttpErrorResponse }>());

export const renewTenantSecret = createAction(`${NAMESPACE} RenewTenantSecret`, props<{ id: string }>());
export const renewTenantSecretComplete = createAction(`${NAMESPACE} RenewTenantSecretComplete`, props<{ tenant: CspTenant }>());
export const renewTenantSecretError = createAction(`${NAMESPACE} RenewTenantSecretError`, props<{ err: HttpErrorResponse }>());

export const deleteSelectedTenant = createAction(`${NAMESPACE} DeleteSelectedTenant`);
export const deleteSelectedTenantComplete = createAction(`${NAMESPACE} DeleteSelectedTenantComplete`);
export const deleteSelectedTenantError = createAction(`${NAMESPACE} DeleteSelectedTenantError`, props<{ err: HttpErrorResponse }>());

export const selectTenant = createAction(`${NAMESPACE} SelectTenant`, props<{ id: string }>());
export const selectTenantComplete = createAction(`${NAMESPACE} SelectTenantComplete`, props<{ tenant: CspTenant }>());
export const selectTenantError = createAction(`${NAMESPACE} SelectTenantError`, props<{ err: HttpErrorResponse }>());

export const goToTenantActions = createAction(`${NAMESPACE} GoToTenantActions`, props<{ id: string }>());
export const goToListPage = createAction(`${NAMESPACE} GoToListPage`);

export const resetState = createAction(`${NAMESPACE} ResetState`, props<Partial<State>>());
