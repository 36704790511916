import { ActivatedRoute, Router } from '@angular/router';

export function isNotNullOrUndefined<T>(input: null | undefined | T): input is T {
  return input !== null && input !== undefined;
}

export function convertBooleanToYesNo(value: boolean): string {
  return value ? 'Yes' : 'No';
}

/** Use in getQuickFilterText
 * @returns an empty string to exclude column from search
 */
export function excludeColumnFromSearch(): string {
  return '';
}

export const formatSizeFromBytes = (size: number, decimalPlaces: number): string =>
  size > 1024 * 1024 ? +(size / (1024 * 1024)).toFixed(decimalPlaces) + ' MB' : +(size / 1024).toFixed(decimalPlaces) + ' KB';

export const routerGoBack = (router: Router, route: ActivatedRoute): void => {
  // Start with the current route
  let activeRoute = route;

  // Traverse up to find the appropriate parent
  while (activeRoute.parent && activeRoute.snapshot.url.length === 0) {
    activeRoute = activeRoute.parent;
  }

  // Check if a valid parent exists, else navigate to root
  const parentRoute = activeRoute.parent;

  if (parentRoute) {
    // Get the full path of the parent route to navigate to it
    const parentUrl = parentRoute.pathFromRoot
      .map(segment => segment.snapshot.url.map(url => url.path).join('/'))
      .filter(Boolean) // Remove empty segments
      .join('/');

    router.navigateByUrl('/' + parentUrl);
  } else {
    // If no parent route, navigate to root
    router.navigate(['/']);
  }
};
